import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'


import SubcategoryContainer from './subcategory-page-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <SubcategoryContainer {...props}/>
        </Provider>
    )
  }
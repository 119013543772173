import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'

import SorpaMap from '../../components/sorpa-map'
import ClosestStation from '../../components/closest_station'
import CategorySearch from '../../components/category_search'
import NextEmptying from '../../components/next_emptying'

import { store } from '../../store'
import { getPricelist } from '../../slices/pricelist'
import Pricelist from '../../components/pricelist'
import { getStations } from '../../slices/stations'
import OpeningHoursSingle from '../../components/opening-hours-single'


export default function SubcategoryContainer(props) {

    const dispatch = useDispatch()
    const stations = useSelector((state) => state.stations.stationList.items)


    useEffect(() => {
        dispatch(getStations({ station_types: "mottokustod" }))
    }, [])

    const subcategoryTypesInfo = {
        '21': 'Urðunarstaður',
        '23': 'GAJA, gas- og jarðgerðarstöð',
        '40': 'Móttöku- og flokkunarstöð'
    }

    const subTypes = "" + props.stationTypes
    

    return (
        <Provider store={store}>
            {props.subcategoryType !== "companies" &&
                <>
                    <div className="title-container mb-0">
                        <h3 className="d-block border-bottom pb-1 mb-3">Móttökustaðir</h3>
                    </div>
                    <ClosestStation {...props} type="grenndarstod" typeVerbose="Grenndarstöðvar" color={props.color} link={props.glink} />

                    <ClosestStation {...props} type="endurvinnslustod" typeVerbose="Endurvinnslustöðvar" color={props.color} link={props.elink} showFetchInfo />
                    <Pricelist subcategoryId={props.subcategoryId} stationType={"50"} />
                </>
            }

            {props.subcategoryType === "companies" &&
                <>
                    <ClosestStation {...props} type="mottokustod,mottokustod_fyrirtaekja"  color={props.color} link={props.flink} showFetchInfo showTitle />
                    <Pricelist showGroupHeading={false} subcategoryId={props.subcategoryId} stationType={subTypes} />
                </>
            }


        </Provider>
    )
}

SubcategoryContainer.defaultProps = {
    subcategoryTypes: ''
}
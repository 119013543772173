import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'


import CategorysearchContainer from './categorysearch-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <CategorysearchContainer {...props}/>
        </Provider>
    )
  }
import React, { useEffect} from 'react'

import { Provider, useDispatch } from 'react-redux'
import CategorySearch from '../../components/category_search'

export default function SearchContainer(props) {
    const dispatch = useDispatch()

    return (
        <>
            <CategorySearch showSuggestions={false} style={props.style}></CategorySearch>
        </>
    )
}
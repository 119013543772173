import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getStations } from '../../slices/stations';

import moment from 'moment';
import OpeningHoursSingle from '../opening-hours-single';

export default function OpeningHoursDetail(props) {

    const openingHoursObject = props.station.opening_hours

    const weekdayPrefixes = [
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
    ]

    const weekdayNames = {
        'mon': 'Mánudaga',
        'tue': 'Þriðjudaga',
        'wed': 'Miðvikudaga',
        'thu': 'Fimmtudaga',
        'fri': 'Föstudaga',
    }
    
    let finalOpeningHours = []

    if (openingHoursObject) {

        const weekdayFromTimes = weekdayPrefixes.map(w => openingHoursObject[`${w}_from`])
        const weekdayToTimes = weekdayPrefixes.map(w => openingHoursObject[`${w}_to`])
        const weekdayClosed = weekdayPrefixes.map(w => openingHoursObject[`${w}_closed`])
        const weekdaySubtexts = weekdayPrefixes.map(w => openingHoursObject[`${w}_subtext_applies`])

        let allWeekdaysSameFrom = weekdayFromTimes.every(item => item === weekdayFromTimes[0]);
        let allWeekdaysSameTo = weekdayToTimes.every(item => item === weekdayToTimes[0]);
        let allWeekdaysSameClosed = weekdayClosed.every(item => item === weekdayClosed[0]);
        let allSubtextSame = weekdaySubtexts.every(item => item === weekdaySubtexts[0]);

        if (allWeekdaysSameFrom && allWeekdaysSameTo && allWeekdaysSameClosed && (allSubtextSame && openingHoursObject.subtext !== "")) {
            finalOpeningHours.push({
                text: 'Virka daga',
                from: moment(openingHoursObject[`mon_from`], "HH:mm:ss"),
                to: moment(openingHoursObject[`mon_to`], "HH:mm:ss"),
                closed: openingHoursObject[`mon_closed`],
                subtext_applies: openingHoursObject[`mon_subtext_applies`],
            })
        } else {
            weekdayPrefixes.map(wdp => {
                finalOpeningHours.push({
                    text: weekdayNames[wdp],
                    from: moment(openingHoursObject[`${wdp}_from`], "HH:mm:ss"),
                    to: moment(openingHoursObject[`${wdp}_to`], "HH:mm:ss"),
                    closed: openingHoursObject[`${wdp}_closed`],
                    subtext_applies: openingHoursObject[`${wdp}_subtext_applies`],
                })
            })
        }

        finalOpeningHours.push({
            text: 'Laugardaga',
            from: moment(openingHoursObject[`sat_from`], "HH:mm:ss"),
            to: moment(openingHoursObject[`sat_to`], "HH:mm:ss"),
            closed: openingHoursObject[`sat_closed`],
            subtext_applies: openingHoursObject[`sat_subtext_applies`],
        })
        finalOpeningHours.push({
            text: 'Sunnudaga',
            from: moment(openingHoursObject[`sun_from`], "HH:mm:ss"),
            to: moment(openingHoursObject[`sun_to`], "HH:mm:ss"),
            closed: openingHoursObject[`sun_closed`],
            subtext_applies: openingHoursObject[`sun_subtext_applies`],
        })

    }

    return (
        <>
            <div className="opening-hrs-container">
                <OpeningHoursSingle station={props.station} />

                <span className="font-small demi-weight">Opnunartímar</span><br />
                {finalOpeningHours.map(o =>
                    <div className="font-small">
                        <>
                            {o.text}: {o.closed ? 'Lokað' : `${o.from.format("H:mm")} - ${o.to.format("H:mm")}`} {o.subtext_applies ? "*" : ""}<br />
                        </>
                    </div>
                )}
                {openingHoursObject && openingHoursObject.subtext !== "" && openingHoursObject.subtext !== null &&
                    <div className="font-small">* {openingHoursObject.subtext}</div>
                }
            </div>
        </>
    )

}

OpeningHoursDetail.defaultProps = {
    station: {},
}
import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/submit_contact_form/";

const submitForm = (args) => {
    args.orgid = '00D06000001bPvE'

    return axios
        .post(API_URL, args, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
};

const ContactUsService = {
    submitForm
};

export default ContactUsService;
import React from 'react';

import accounting from 'accounting'


export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}


export const formatMoney = (num, decimals = 0) => {
  return accounting.formatMoney(+num, '', decimals, ".", ",")
}


export const GetQueryStringParams = (sParam) => {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}

export const renderSubtext = (subtext) => {
  const lines = subtext.split("\n")
  return (
    <>
      { lines.map(line => 
        <>{line}<br /></>
      )}
    </>
  )
}
import React from 'react'

export const SvgSearchIcon = (props) => {
    return (
        <svg className="search-icon" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="S-forsíða" transform="translate(-1846.000000, -49.000000)" fill="#000000" fillRule="nonzero">
                    <g id="Group" transform="translate(1718.368000, 49.000000)">
                        <g id="Group-Copy-5" transform="translate(127.632000, 0.000000)">
                            <path d="M10.285176,0 C4.617048,0 0,4.61701045 0,10.2851798 C0,15.9533183 4.61704114,20.5703699 10.285176,20.5703699 C12.7510217,20.5703699 15.0140126,19.6994784 16.788408,18.2454898 L22.2416949,23.69877 C22.6433246,24.1003929 23.2971154,24.1004271 23.6987623,23.69877 C24.1004126,23.2971128 24.1004126,22.65405 23.6987623,22.2524271 L18.2454754,16.7991126 C19.697472,15.0253411 20.5703554,12.7491125 20.5703554,10.2851798 C20.5703554,4.61701045 15.9533074,0 10.285176,0 L10.285176,0 Z M10.285176,2.05703664 C14.8415966,2.05703664 18.5133189,5.7287282 18.5133189,10.2851798 C18.5133189,14.841604 14.8415897,18.5133298 10.285176,18.5133298 C5.72876571,18.5133298 2.05703657,14.841604 2.05703657,10.2851798 C2.05703657,5.7287282 5.72875886,2.05703664 10.285176,2.05703664 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const CloseIcon = (props) => {
    return (
        <svg className="close" version="1.1" id="Layer_1" x="0px" y="0px"
            viewBox="0 0 20 20.1" styles="enable-background:new 0 0 20 20.1;">
        <path id="X" fill="#232323" d="M18.8,0.2c0.3-0.2,0.7-0.2,1,0.1s0.3,0.8,0,1.1L11.1,10l8.7,8.7c0.3,0.3,0.3,0.8,0,1.1
            c-0.3,0.3-0.7,0.3-1,0.1l-0.1-0.1L10,11.1l-8.7,8.7l-0.1,0.1c-0.3,0.2-0.7,0.2-1-0.1c-0.3-0.3-0.3-0.8,0-1.1L8.9,10L0.2,1.3
            c-0.3-0.3-0.3-0.8,0-1.1s0.7-0.3,1-0.1l0.1,0.1L10,8.9L18.8,0.2L18.8,0.2z"/>
        </svg>
    )
}

export const ArrowDown = (props) => {
    return (
        <svg className="arrow-down" width="11px" height="18px" viewBox="0 0 11 18">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="S-Sorpa-Útgefið-efni" transform="translate(-1387.000000, -549.000000)" fill="#000000">
                    <g id="Group-4" transform="translate(495.000000, 524.000000)">
                        <path d="M897.8,25 L897.799579,38.992 L900.946254,35.8455399 L902.100714,37 L897.029646,42.0710678 L891.958579,37 L893.113039,35.8455399 L896.199579,38.932 L896.2,25 L897.8,25 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const FileIcon = (props) => {
    return (
        <svg className="file-icon" width="22px" height="30px" viewBox="0 0 22 30" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="S-Sorpa-Útgefið-efni" transform="translate(-515.000000, -542.000000)">
                    <g id="Group-4" transform="translate(495.000000, 524.000000)">
                        <g id="Group-3" transform="translate(20.000000, 18.000000)">
                            <polygon id="Rectangle" fill="#009EC9" points="-1.16582435e-13 -1.29078467e-13 12.8399797 -1.29078467e-13 22 9.27074316 22 30 -9.58852508e-14 30"></polygon>
                            <polygon id="Path-5" fill="#CBDEE7" points="22 9 13 9 13 2.0166911e-16"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const ArrowHead = (props) => {
    return (
        <svg className="arrow-head" width="15px" height="9px" viewBox="0 0 15 9" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="S-Sorpa-Útgefið-efni" transform="translate(-872.000000, -373.000000)" stroke="#232323" strokeWidth="2">
                    <g id="Group-8-Copy-2" transform="translate(712.000000, 354.000000)">
                        <polyline id="Path-3" points="161 20 167.5 26 174 20"></polyline>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const BlueArrowHead = (props) => {
    return (
        <svg width="14px" height="6px" viewBox="0 0 14 6" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="S-contentpage--styles" transform="translate(-1387.000000, -6662.000000)" stroke="#009EC9">
                    <g id="Group-8" transform="translate(969.000000, 6634.000000)">
                        <polyline id="Path-3" transform="translate(425.000000, 31.000000) scale(1, -1) translate(-425.000000, -31.000000) " points="419 28 425 34 431 28"></polyline>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const LocationIcon = (props) => {
    return (
        <svg version="1.1" id="pin_x27_" x="0px" y="0px"
            viewBox="0 0 13 17">
            <g id="Location" transform="translate(368.000000, 272.000000)">
            <path id="Shape" d="M-361.5-272c-3.6,0-6.5,2.8-6.5,6.2c0,2.5,1.6,5.1,3.1,7.2c1.5,2.1,3.1,3.5,3.1,3.5c0.2,0.2,0.4,0.2,0.6,0
                c0,0,1.5-1.5,3.1-3.5s3.1-4.7,3.1-7.2C-355-269.2-357.9-272-361.5-272L-361.5-272z M-361.5-271.2c3.1,0,5.7,2.4,5.7,5.4
                c0,2.2-1.5,4.7-3,6.7c-1.4,1.8-2.5,2.9-2.7,3.1c-0.2-0.2-1.4-1.3-2.7-3.1c-1.5-2-3-4.6-3-6.7C-367.2-268.8-364.6-271.2-361.5-271.2
                z"/>
            <path id="Shape_00000075856104613921456180000005497918062237230208_" d="M-361.5-268.5c-1.6,0-2.8,1.2-2.8,2.7
                c0,1.5,1.3,2.7,2.8,2.7s2.8-1.2,2.8-2.7C-358.7-267.3-359.9-268.5-361.5-268.5z M-361.5-267.8c1.1,0,2,0.9,2,1.9s-0.9,1.9-2,1.9
                s-2-0.9-2-1.9S-362.6-267.8-361.5-267.8z"/>
            </g>
        </svg>
    )
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SearchService from "../../api/search";

export const getCategories = createAsyncThunk(
    "categories/get",
    async (args, thunkAPI) => {
      try {
        const response = await SearchService.getCategories(args);
        //thunkAPI.dispatch(setMessage(response.data.message));
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
);


export const logSearchString = createAsyncThunk(
  "categories/get",
  async (args, thunkAPI) => {
    try {
      const response = await SearchService.setMissedSearch(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.setMissedSearch.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
); 
const initialState = { categories: [] };

const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    extraReducers: {
      [getCategories.fulfilled]: (state, action) => {
        state.categories = action.payload.items;
      },
      [getCategories.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja flokka";
      },
    },
  });


const { reducer } = categoriesSlice;
export default reducer;
import { applyMiddleware, combineReducers, createStore } from 'redux';
// import { stepRenderer } from './api/step-renderer/reducers'
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
// import authReducer from './slices/auth';
// import modal from './slices/modal';
import stationsReducer from './slices/stations';
import pricelistReducer from './slices/pricelist';
import searchReducer from './slices/search';
import newsReducer from './slices/news';
import selectedStationReducer from './slices/selected-station';
import publishedContentReducer from './slices/published-content';
// import subscriptionsReducer from './slices/subscriptions';
// import temporaryTokenReducer from './slices/temporaryToken';

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    // modal,
    news: newsReducer,
    stations: stationsReducer,
    pricelist: pricelistReducer,
    search: searchReducer,
    selectedStation: selectedStationReducer,
    documents: publishedContentReducer
})


let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
        
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
    ))
}

export const store = createStore(reducers, middleware)
import React, { useEffect, useState, useRef } from 'react';

import { Provider, useDispatch } from 'react-redux'
import { store } from '../../store'
import SorpaMap from '../../components/sorpa-map';
import StationIndex from '../../components/station_index';
import { getStations } from '../../slices/stations';
import { CloseIcon } from '../../components/svg';

export default function StationIndexPageContainer(props) {

    const dispatch = useDispatch()
    const [addedClass, setAddedClass] = useState("")

    let type = props.type
    if (type === 'mottokustod_fyrirtaekja') {
        type = 'mottokustod,mottokustod_fyrirtaekja'
    }
    useEffect(() => {
        dispatch(getStations({'station_types': type }))
    }, [])

    function addingClass(style){
        setAddedClass(style)
        if(style){
            $('body').addClass('position-fixed')
        } else {
            $('body').removeClass('position-fixed')
        }

    }

    return (
        <>
            <div className="col-xl-6 col-lg-8 offset-xl-1 offset-lg-0 col-14 offset-1 left">
                <StationIndex title={props.title} type={props.type} alternatePageText={props.alternatePageText} alternatePageLink={props.alternatePageLink} styleCallback={() =>  addingClass('show-map')}></StationIndex>
            </div>
            <div className={`col-xl-6 col-lg-7 offset-xl-1 px-0 sorpa-map-container ${addedClass}`}>
                <button className="d-block d-lg-none close-map medium-weight font-small mb-4 float-end border-0 d-flex align-items-center" onClick={() => addingClass('')}>Loka korti <CloseIcon /></button>
                <SorpaMap />
            </div>
        </>
    )
  }
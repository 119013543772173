import React, { useEffect, useState, useRef, useMemo } from 'react';
import { getCategories, logSearchString } from '../../slices/search';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash'
import { SvgSearchIcon } from '../svg'

export default function CategorySearch(props) {
	const { showSuggestions, style } = props
	const appId = window.algolia.appId
	const ix = window.algolia.index
	const frontendKey = window.algolia.frontendKey

	const dispatch = useDispatch()
	const algoliasearch = require("algoliasearch");
	const client = algoliasearch(appId, frontendKey);
	const index = client.initIndex(ix);
	const categories = useSelector((state) => state.search.categories);
	const inputEl = useRef("searchInput");
	const [searchInput, setSearchInput] = useState('');
	const [selectedCategory, setSelectedCategory] = useState("");
	const [categoriesOpen, setCategoriesOpen] = useState(false);
	const [searchDisplay, setSearchDisplay] = useState("");
	const [youtubeDisplay, setYoutubeDisplay] = useState(false);
	const containerRef = useRef();
	useOnClickOutside(containerRef, () => clearAll());
	

	const searchSettings = {
		'facetFilters': [
			[
				"model:sorpa_cms.SearchWord"
			]],
		'distinct': 1
	}

	const magicWords = ["afi", "afa", "afinn", "afann"]

	// Hook
	function useOnClickOutside(ref, handler) {
		useEffect(() => {
			const listener = (event) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener("mousedown", listener);
			document.addEventListener("touchstart", listener);
			return () => {
				document.removeEventListener("mousedown", listener);
				document.removeEventListener("touchstart", listener);
			};
		},
		// Add ref and handler to effect dependencies
		[ref, handler]
		);
	}

	const debounceLoadData = useMemo(() => debounce((searchInput) => {
        dispatch(logSearchString(searchInput));
    }, 300), []);
	  

	useEffect(() => {
		if (searchInput.length >= 2) {
			if (magicWords.includes(searchInput)) {
				setYoutubeDisplay(true)
			}
			else {
			index.search(searchInput, searchSettings)
				.then(({ hits }) => {
					if(hits.length === 0){
						debounceLoadData(searchInput)
					}
					setSearchDisplay(hits)
					setYoutubeDisplay(false)
				})
			}
		} else {
			setSearchDisplay("")
			setYoutubeDisplay(false)
		}
	}, [searchInput])

	function clearAll() {
		setSelectedCategory("")
		setSearchInput("")
		setCategoriesOpen(false)
		setSearchDisplay("")
		setYoutubeDisplay(false)
		dispatch(getCategories())
	}

	const RenderCategory = (props) => {
		let { cat, render_children, i } = props

		return (<li className={`list-group-item flex-wrap d-flex align-items-center medium-weight font-black rounded-0 cursor-pointer cat-container border-end-0 border-start-0 border-top border-bottom ps-0 position-relative`} ey={i} onClick={(e) => setSelectedCategory(cat)}>
			<span style={{ backgroundColor: `${cat.color}` }} className="colorbar d-block me-4"></span>
			{cat.title}
			{render_children && cat.sub.length > 0 &&
				<ul className="list-group flex-row flex-wrap position-absolute start-0 inner-ul">
					{cat.sub.map(s => {
						return s.active && (
							<li className="list-group-item border-0 cat-container ps-0 inner-li">
								<a className="d-flex flex-column" href={s.url}>
									<div className="d-inline-block">
										<img src={s.icon.url} alt={`Táknmynd fyrir flokkinn ${cat.title}`} /><span className="font-D-DIN font-light d-flex align-items-center justify-content-center text-center text-center" style={{ borderColor: `${cat.color}` }}>{s.title}</span>
									</div>
								</a>
							</li>
						)
					})}
				</ul>
			}
		</li>);
	}

	const RenderCategories = () => {
		if (selectedCategory) {
			return <>
				<a className={`back d-flex align-items-center position-absolute font-medium font-black medium-weight cursor-pointer`} onClick={e => setSelectedCategory('')}>
					<svg className="arrow-back" viewBox="0 0 8 16">
						<path fill="none" stroke="#141414" strokeWidth="1.5" d="m1 15 6-7-6-7" />
					</svg>
					Til baka
				</a>
				<ul className="categories position-absolute w-100 list-group-flush border-blue rounded-bottom pt-increase list-open">
					<RenderCategory cat={selectedCategory} i={0} render_children={true}></RenderCategory>
				</ul>
			</>
		} else {
			return <ul className={`categories position-absolute w-100 list-group-flush rounded-bottom ${categoriesOpen ? 'list-open' : ''}`}>
				{categories && categories.map((cat, i) =>
					<RenderCategory cat={cat} i={i} render_children={false}></RenderCategory>
				)}
			</ul>;
		}
	}

	const RenderSearch = () => {
		return (<ul className={`categories search-results overflow-scroll position-absolute w-100 list-group-flush rounded-bottom ${searchDisplay ? 'list-open' : ''}`}>
			{searchDisplay && searchDisplay.map((item, i) =>
				{ return item.sorpa_cms__SearchWord.subcategory_active && 	
					<li className="list-group-item p-0">
						<a className="font-black" href={`${item.sorpa_cms__SearchWord.subcategory_url}?back=/`}>
							{item.sorpa_cms__SearchWord.word}
						</a>
					</li>
				}
			)}
		</ul>);
	}

	const RenderYoutube = () => {
		return (
			<div className="iframe-youtube">
				<iframe width="560" height="315" src="https://www.youtube.com/embed/msu0Ra8bj7Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
		)
	}

	return (
		<div ref={containerRef} className={`category-search position-relative mb-40 ${categoriesOpen && 'is-focused'} ${style}`}>
			{style === 'secondary' &&
			<SvgSearchIcon />
			}
			<input onClick={() => setCategoriesOpen(true)} className={`w-100 search position-relative cursor-pointer rounded-top p-5 ${style==='secondary' ? 'border-grey' : 'border-blue'} font-black demi-weight ${(style === 'secondary' && searchDisplay) && 'flat-bottom'} ${(!categoriesOpen || !showSuggestions) && 'rounded-bottom'}`} onChange={e => { setSearchInput(e.target.value) }} value={searchInput} ref={inputEl} type="text" placeholder="Hvað á að gera við..." />
			<div onClick={() => clearAll()} className="close-icon-container position-absolute cursor-pointer top-0 end-0 d-flex align-items-center justify-content-center">
				<svg className="close" viewBox="0 0 20 20">
					<path fill="#525252" d="M18.8.2c.3-.2.7-.2 1 .1.3.3.3.8 0 1.1L11.1 10l8.7 8.7c.3.3.3.8 0 1.1-.3.3-.7.3-1 .1l-.1-.1-8.7-8.7-8.7 8.7-.1.1c-.3.2-.7.2-1-.1-.3-.3-.3-.8 0-1.1L8.9 10 .2 1.3C-.1 1-.1.5.2.2c.3-.3.7-.3 1-.1l.1.1L10 8.9 18.8.2z" />
				</svg>
			</div>
			{!searchDisplay && categoriesOpen && showSuggestions && !youtubeDisplay &&
				<RenderCategories />
			}
			{searchDisplay && !youtubeDisplay &&
				<RenderSearch />
			}
			{youtubeDisplay &&
				<RenderYoutube />
			}
		</div>
	)

}
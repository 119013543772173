import axios from "axios";
import { getCookie } from "../../utils";
import moment from 'moment';


const API_URL = "/api/v2/pages/";


const getStations = (args) => {
    let params = '?type=sorpa_cms.StationPage&fields=title,lat,lng,area,address,postal_code,station_type,opening_hours_today,opening_hours_tomorrow,opening_hours,categorylist,short_description,graenir_skatar&limit=1000'
    if (args && args['station_types']) {
        params = `${params}&station_type=${args.station_types}`
    }
    
    return axios
        .get(API_URL + `${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getClosestStations = (args) => {
    const today = moment().format('ddd').toLowerCase()
    var url = `/api/get_closest_stations/?lat=64.127551&lon=-21.863443`
    var geo = false
    if (args && args.position && args.position.coords && args.position.coords.latitude && args.position.coords.longitude) {
        const {latitude,longitude} = args.position.coords;
        url = `/api/get_closest_stations/?lat=${latitude}&lon=${longitude}`
        geo = true
    }
    if(args && args['type']){
        url += '&type='+args['type']
    }
    if(args && args['subcategory']){
        url += '&subcategory='+args['subcategory']
    }
    if(args && args['type'] && args['type']=='endurvinnslustod'){
        url += '&day_to=' + today + "_to"
    }

    return axios
        .get(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            let val = {
                type: args.type,
                data: response.data,
                geo: geo
            }

            return val;
        });

};



const stationService = {
    getStations,
    getClosestStations
};

export default stationService;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getNews } from '../../slices/news';

export default function NewsIndexPage(props) {
	const dispatch = useDispatch()
	const news = useSelector((state) => state.news.data)
    const limit = props.limit
    var offset = news.length
    

	return (
        <>
        { news && news.map((item, i) => {
            return <div className={`col-xl-4 col-md-6 col-14 news-block ${i % 3 == 0 ? 'offset-xl-2' : ''} ${i % 2 == 0 ? 'offset-xl-0 offset-sm-2' : ''}`}>
                <a href={item.meta.slug} className="text-decoration-none h-100">
                    <div className="news position-relative d-flex align-items-end flex-column h-100">
                        <div className="img-container overflow-hidden">
                        { item.header_image_thumb &&
                            <img src={item.header_image_thumb.url} alt={item.header_image_thumb.alt} className="h-100"/>
                        }
                        </div>
                        <div className="text-container position-relative d-flex h-100">
                            <div className="w-100 inner position-relative">
                                <span className="blue font-small pt-2 d-block">{ moment(item.meta.first_published_at).locale('is').format('DD. MMM YYYY') }</span>
                                <h5 className="title demi-weight overflow-hidden font-black d-inline">{item.title}</h5>
                                <span className="blue medium-weight position-absolute bottom-0 start-0 font-medium">Lesa meira</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        })
        }
        <div className="row justify-content-center">
            <div className='col-lg-2 col-6'>
                <button className="btn border rounded-pill font-small demi-weight font-black w-100 py-3 mb-60" onClick={() => dispatch(getNews({limit: props.limit, offset: offset }))}>Fleiri fréttir</button>
            </div>
        </div>
        </>
    )

}

NewsIndexPage.defaultProps = {}
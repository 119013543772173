import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { formatMoney } from '../../utils';
import { getPricelist } from '../../slices/pricelist';

export default function Pricelist(props) {
    const pricelist = useSelector((state) => state.pricelist.pricelist)

    const dispatch = useDispatch()

    const doFormatMoney = (priceline, multiply = false) => {
        if (priceline.price === 0.0) {
            return "Gjaldfrjálst"
        }
        let price = priceline.price
        if (priceline.always_charge && multiply) {
            price = priceline.price * 2
        }
        if (formatMoney(price, 2) === "0,00" && priceline.unit === "KG") {
            return <>{formatMoney(price, 0)} kr.</>
        } else {
            if (priceline.unit !== "KG") {
                return <>{formatMoney(price, 0)} kr.</>
            } else {
                return <>{formatMoney(price, 2)} kr.</>
            }
        }
    }

    useEffect(() => {
        dispatch(getPricelist({ subcategory: props.subcategoryId, stype: props.stationType }))
    }, [])

    if (props.displayType === 'default') {

        if (pricelist && pricelist[props.stationType] && pricelist[props.stationType].length > 0) {

            const groups = ['Einstaklingar', 'Fyrirtæki']

            let grouping = {
                'Einstaklingar': [],
                'Fyrirtæki': [],
            }

            pricelist[props.stationType].map(p => {
                let gg = 'Fyrirtæki'
                if (p.customer_type) {
                    gg = p.customer_type
                }
                grouping[gg].push(p)
            })

            let anchor = ''
            if (props.stationType == '40') {
                anchor = 'fyrirtaeki-mottokustod'
            } else if (props.stationType == '23') {
                anchor = 'fyrirtaeki-gaja'
            } else if (props.stationType == '21') {
                anchor = 'fyrirtaeki-urdunarstadur'
            }


            return (
                <div className="pricelist pricelist-stationpage mb-70">
                    <div className="pricelist--header d-flex align-items-center">
                        <h4 className="d-inline-block border-bottom pb-1 mb-0 font-medium">Gjaldskrá</h4>
                        <a href={`${window.pages.pricingPage}${anchor ? '#' + anchor : ''}`} className="font-smaller demi-weight border-blue blue terms ms-auto">Sjá alla gjaldskrá</a>
                    </div>
                    <div className="pricelist-inner-container">
                        <div className="pricelist--body">
                            {groups.map(group =>
                                <>
                                    {grouping[group].length > 0 &&
                                        <>
                                            <div className="pricelist--item header">
                                                <div className="pricelist--item--description no-dots ">
                                                    {props.showGroupHeading &&
                                                        <>
                                                            {grouping[group][0].customer_type ? grouping[group][0].customer_type : 'Fyrirtæki'}
                                                        </>
                                                    }
                                                </div>
                                                {grouping[group][0].unit === "KG" ?
                                                    <>
                                                        <div className="pricelist--item--price"><br />kr./kg m.vsk</div>
                                                    </>
                                                    : <>
                                                        {grouping[group][0].unit === "EIN" ?
                                                            <>
                                                                <div className="pricelist--item--price"><br />kr. m.vsk</div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="pricelist--item--price">Allt að 2m<sup>3</sup><br /><span>Hver 0,25m<sup>3</sup></span></div>
                                                                <div className="pricelist--item--price">Umfram 2m<sup>3</sup> <br /><span>Hver 0,25m<sup>3</sup></span></div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            {grouping[group].map(p =>
                                                // <div className="pricelist--body">
                                                <div className="pricelist--item">
                                                    <div className="pricelist--item--description font-medium" title={`${p.description} ${p.comment ? p.comment : ""}`}>{p.description} {p.place != "Endurvinnslustöðvar" ? `(${p.place})` : ''} {p.comment ? <em>{p.comment}</em> : <></>}</div>
                                                    {grouping[group][0].unit !== "KG" &&
                                                        <div className="pricelist--item--price font-medium">
                                                            <>
                                                                {p.always_charge ?
                                                                    <>
                                                                        {doFormatMoney(p)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Gjaldfrjálst
                                                                    </>
                                                                }
                                                            </>
                                                        </div>
                                                    }
                                                    <div className="pricelist--item--price font-medium">{doFormatMoney(p, true)}</div>
                                                </div>
                                                // </div> 
                                            )}
                                        </>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    } else {

        if (pricelist && pricelist[props.stationType] && pricelist[props.stationType].length > 0) {

            const groups = ['Einstaklingar', 'Fyrirtæki']

            let grouping = {
                'Einstaklingar': {},
                'Fyrirtæki': {},
            }

            let categoryColors = {}
            let categoryLinks = {}

            pricelist[props.stationType].map(p => {
                let gg = 'Fyrirtæki'
                if (p.customer_type) {
                    gg = p.customer_type
                }
                let cc = 'Annað'
                if (p.category) {
                    cc = p.category
                    categoryColors[p.category] = p.category_color
                    categoryLinks[p.category] = p.category_url
                }

                if (Object.keys(grouping[gg]).includes(cc)) {
                    grouping[gg][cc].push(p)
                } else {
                    grouping[gg][cc] = [p]
                }
            })


            let ordering = [
                "Garðaúrgangur",
                "Matarleifar",
                "Nytjahlutir",
                "Pappír",
                "Pappi",
                "Gler og postulín",
                "Byggingaúrgangur",
                "Raf- og rafeindatæki",
                "Áhættuúrgangur",
                "Plast og gúmmí",
                "Málmar",
                "Blandaður úrgangur",
            ]
            let orderingIncludingOther = ordering.concat(["Annað"])

            let extraCategories = {
                "Einstaklingar": Object.keys(grouping["Einstaklingar"]).filter(f => !orderingIncludingOther.includes(f)),
                "Fyrirtæki": Object.keys(grouping["Fyrirtæki"]).filter(f => !orderingIncludingOther.includes(f))
            }

            let loopOrdering = {
                "Einstaklingar": ordering.concat(extraCategories["Einstaklingar"]).concat(["Annað"]),
                "Fyrirtæki": ordering.concat(extraCategories["Fyrirtæki"]).concat(["Annað"])
            }

            return (
                <>
                    <div className="mb-70">
                        {groups.map(group =>
                            <>
                                {props.displayGroups.includes(group) &&
                                    <div className="pricelist--body">
                                        {loopOrdering[group].map(category =>
                                            <>
                                                {grouping[group][category] &&
                                                    <>
                                                        <div className="pricelist-table-container">
                                                            < div className="pricelist--item header">
                                                                <div className="pricelist--item--description" >
                                                                    <span className="pricelist--item--color" style={{ backgroundColor: categoryColors[category] }}></span>
                                                                    {categoryLinks[category] ?
                                                                        <a href={categoryLinks[category]}>{category}</a>
                                                                        :
                                                                        <>{category}</>
                                                                    }
                                                                </div>
                                                                {grouping[group][category][0].unit === "KG" ?
                                                                    <>
                                                                        <div className="pricelist--item--price"><br />kr./kg m.vsk</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {grouping[group][category][0].unit === "EIN" ?
                                                                            <>
                                                                                <div className="pricelist--item--price"><br />kr. m.vsk</div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="pricelist--item--price">Allt að 2m<sup>3</sup><br /><span>Hver 0,25m<sup>3</sup></span></div>
                                                                                <div className="pricelist--item--price">Umfram 2m<sup>3</sup> <br /><span>Hver 0,25m<sup>3</sup></span></div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>

                                                            {grouping[group][category].length > 0 &&
                                                                <>
                                                                    {grouping[group][category].map(p =>
                                                                        <div className="pricelist--item">
                                                                            <div className="pricelist--item--code font-medium">{p.number}</div>
                                                                            <div className="pricelist--item--description font-medium" title={`${p.description} ${p.comment ? p.comment : ""}`}>{p.description} {p.comment ? <em>{p.comment}</em> : <></>}</div>
                                                                            {grouping[group][category][0].unit !== "KG" &&
                                                                                <div className="pricelist--item--price font-medium">
                                                                                    {p.always_charge ?
                                                                                        <>
                                                                                            {doFormatMoney(p)}
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {grouping[group][category][0].unit === "EIN" ?
                                                                                                <></>
                                                                                                :
                                                                                                <>Gjaldfrjálst</>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="pricelist--item--price font-medium">{doFormatMoney(p, true)}</div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )}
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </>
            )
        } else { return <></> }
    }

}

Pricelist.defaultProps = {
    displayType: 'default',
    displayGroups: ['Einstaklingar', 'Fyrirtæki'],
    showGroupHeading: true,
}
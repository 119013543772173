import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setSelected } from '../../slices/selected-station';
import OpeningHoursSingle from '../opening-hours-single';

import moment from 'moment';
import { LocationIcon } from '../svg';

const sortArr = ['Reykjavík', 'Kópavogur', 'Garðabær', 'Hafnarfjörður', 'Mosfellsbær', 'Álfsnes', 'Ölfus']

function compare(a, b) {
    if (sortArr.indexOf(a) < sortArr.indexOf(b)) { return -1 }
    if (sortArr.indexOf(a) > sortArr.indexOf(b)) { return 1 }
    else { return 0 }
}

export default function StationIndex(props) {
    const stations = useSelector((state) => state.stations.stationList)
    const dispatch = useDispatch()
    const [confStations, setConfStations] = useState({})
    const [openAreaMenu, setOpenAreaMenu] = useState("")
    const [openPostMenu, setOpenPostMenu] = useState("")

    
    useEffect(() => {
        if (stations.items && stations.items.length !== 0) {
            let stObj = {}
            for (var item of stations.items) {
                if (item.area in stObj) {
                    if (item.postal_code in stObj[item.area]) {
                        stObj[item.area][item.postal_code].push(item)
                    } else {
                        stObj[item.area][item.postal_code] = [item]
                    }
                } else {
                    stObj[item.area] = {}
                    stObj[item.area][item.postal_code] = [item]
                }
            }
            Object.keys(stObj).sort(compare)
            setConfStations(stObj)
        }
    }, [stations])

    const doSetSelected = (station) => {
        dispatch(setSelected(station))
        $('.mapboxgl-map')[0].scrollIntoView()
        if ($(window).width() < 992) {
            props.styleCallback()
        }
        
    }


    const RenderGrenndarStodvar = (props) => {
        return (
            <div className="">
                <h2 className="mb-4 demi-weight font-black">{props.title}</h2>
                <div className="d-block d-lg-none border-blue font-smaller demi-weight font-light py-4 w-100 see-map text-center rounded mb-4" onClick={props.styleCallback}>Sjá grenndarstöðvar á korti</div>
                <ul className="list-group ps-0">
                    {confStations && Object.keys(confStations).map((area) => {
                        return <li className={`parent list-group-item border-end-0 border-start-0 font-black border-blue rounded-0 p-0 demi-weight ${openAreaMenu == area ? 'open' : ''}`}>
                            <span className="d-block w-100 ps-4 open-postal" onClick={() => setOpenAreaMenu(openAreaMenu == area ? "" : area)}>{area}</span>
                            <div className={`arrow-container position-absolute ${openAreaMenu == area ? "active" : ""}`}><div className="arrow"><span></span><span></span></div></div>
                            <ul className="list-group inner-ul ps-4" data-height="">
                                {Object.values(confStations[area]).flat().length > 10 ?
                                    Object.keys(confStations[area]).map((postalCode) => {
                                        return <li className={`middle pt-0 list-group-item border-end-0 border-start-0 rounded-0 px-0 position-relative ${openPostMenu == postalCode ? 'open' : ''}`}>
                                            <span className="font-medium medium-weight d-block open-postal w-100" onClick={() => setOpenPostMenu(openPostMenu == postalCode ? "" : postalCode)}>{postalCode} {area}</span>
                                            <div className={`arrow-container position-absolute ${openPostMenu == postalCode ? "active" : ""}`}><div className="arrow"><span></span><span></span></div></div>
                                            <ul className="list-group">
                                                {confStations[area][postalCode].map((station) => {
                                                    return (
                                                        <li className="list-group-item font-black font-small d-flex flex-wrap align-items-center rounded ps-4 py-3 py-md-0 demi-weight mb-4 flex-md-nowrap flex-wrap">
                                                            {station.title}
                                                            <ul className="list-group icons-list list-group-horizontal ms-md-auto justify-content-md-end pt-2 order-4 order-md-2">
                                                                {station.categorylist.map((category) => {
                                                                    return <li className={`list-group-item icons me-2 mb-2 d-flex align-items-center rounded-0 border-0 p-0 me-2 ${category.icon.title}`}>
                                                                        <img src={category.icon.url} alt={category.icon.title} title={category.icon.title} />
                                                                    </li>
                                                                })}
                                                                {station.graenir_skatar &&
                                                                    <li className={`list-group-item icons me-2 mb-2 rounded-0 border-0 p-0 me-2 `}>
                                                                        <img alt={`Dósasöfnun grænna skáta`} src={window.sorpaIcons.graenir_skatar} title="Grænir skátar taka við dósum" />
                                                                    </li>
                                                                }
                                                            </ul>
                                                            <div class="md-break order-3"></div>
                                                            <span className="mx-2 font-smaller ms-auto ms-md-2 show-on-map order-2 order-md-4" onClick={() => doSetSelected(station)}>Sýna á korti</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    })
                                :
                                    Object.keys(confStations[area]).map((postalCode) => {
                                        return confStations[area][postalCode].map((station) => {
                                            return (
                                                <li className="list-group-item font-black font-small d-flex flex-wrap align-items-center rounded ps-4 demi-weight mb-4 py-md-0 py-3 flex-md-nowrap flex-wrap">
                                                    {station.title}
                                                    <ul className="list-group icons-list list-group-horizontal ms-md-auto justify-content-md-end pt-2 order-4 order-md-2">
                                                        {station.categorylist.map((category) => {
                                                            return <li className={`list-group-item icons me-2 mb-2 rounded-0 border-0 p-0 me-2 ${category.icon.title}`}>
                                                                <img src={category.icon.url} alt={category.icon.title} title={category.icon.title} />
                                                            </li>
                                                        })}
                                                        {station.graenir_skatar &&
                                                            <li className={`list-group-item icons me-2 mb-2 rounded-0 border-0 p-0 me-2 `}>
                                                                <img alt={`Dósasöfnun grænna skáta`} src={window.sorpaIcons.graenir_skatar} title="Grænir skátar taka við dósum" />
                                                            </li>
                                                        }
                                                    </ul>
                                                    <div class="md-break order-3"></div>
                                                    <span className="mx-2 font-smaller ms-auto ms-md-2 show-on-map order-2 order-md-4" onClick={() => doSetSelected(station)}>Sýna á korti</span>
                                                </li>
                                            )
                                    })
                            })}
                            </ul>
                        </li>
                    }
                    )}
                </ul>
            </div>
        )
    }

    const RenderEndurvinnsluStodvar = (props) => {
        let { title, type, selectedStationId, parentUrl, alternatePageLink, alternatePageText } = props
        return (
            <div className="endurvinnslustodvar-container">
                <h2 className="demi-weight font-black pb-4">{title}</h2>
                <div className="d-block d-lg-none border-blue font-smaller demi-weight font-light py-4 w-100 see-map text-center rounded" onClick={props.styleCallback}>Sjá endurvinnslustöðvar á korti</div>
                <ul className="list-group inner-ul ps-0" data-height="">
                    {stations.items.map((station) =>
                        <li className="list-group-item border-0 px-0 d-flex py-0">
                            <div className={`inner-container col me-md-4 d-flex align-items-center rounded ps-4 py-0 border cursor-pointer ${selectedStationId === station.id ? 'active' : ''}`} onClick={() => doSetSelected(station)}>
                                <span className="me-auto demi-weight font-black font-small">{type === 'endurvinnslustod' ? station.address : station.title}, <span className="font-black font-small normal-weight text-nowrap">{station.postal_code} {station.area}</span></span>
                                <OpeningHoursSingle station={station} classNames="text-nowrap font-black font-smaller d-flex align-items-center normal-weight opening-hrs-span" />
                                {selectedStationId !== null ?
                                    <a className="d-none d-lg-block ms-2 me-4 me-2 font-smaller text-nowrap demi-weight scale-fix" href={`${parentUrl}?selectedStation=${station.id}`}>Sýna á korti</a>
                                :
                                    <span className="d-none d-lg-block ms-5 me-4 me-2 font-smaller text-nowrap demi-weight scale-fix">Sýna á korti</span>   
                                }
                                <div className='d-none me-4' style={{width: '16px'}}><LocationIcon /></div>
                            </div>
                            {selectedStationId === station.id ?
                                <a className="d-lg-flex border rounded more ms-auto align-items-center justify-content-center" href={parentUrl}><span className='font-light demi-weight font-smaller'>Loka</span></a>
                                :
                                <a className="d-lg-flex border rounded more ms-auto align-items-center justify-content-center" href={station.meta.html_url}><span className='font-light demi-weight font-smaller'>Nánar <span className='hide-text demi-weight font-smaller'>um stöðina</span></span></a>
                            }
                        </li>
                    )}
                </ul>
                { alternatePageLink !== '' &&
                    <>
                    <div className='border-blue rounded-top rounded-bottom mt-5 mb-4 py-4 px-20'>
                        <div className="font-medium demi-weight">Móttökustaðir fyrirtækja</div>
                        <p className="font-medium font-lighter" dangerouslySetInnerHTML={{__html: alternatePageText}} />
                    </div>
                    <div className='border-blue rounded-top rounded-bottom py-3 text-center mb-5'>
                        <a href={alternatePageLink} className="font-smaller demi-weight font-light">Sjá móttökustöðvar fyrir fyrirtæki</a>
                    </div>
                    </>         
                }
            </div>
        )
    }

    const RenderMottokustodvarFyrirtaekja = (props) => {
        let { title, type, selectedStationId, parentUrl, alternatePageLink, alternatePageText } = props
        return (
            <div className="endurvinnslustodvar-container">
                <h2 className="demi-weight font-black pb-4">{title}</h2>
                <div className="d-block d-lg-none border-blue font-smaller demi-weight font-light py-4 w-100 see-map text-center rounded" onClick={props.styleCallback}>Sjá {title.toLowerCase()} á korti</div>
                <ul className="list-group inner-ul ps-0" data-height="">
                    {stations.items.map((station) =>
                        <li className="list-group-item border-0 px-0 d-flex py-0">
                            <div className={`inner-container col me-4 d-flex align-items-center rounded ps-4 py-0 border cursor-pointer ${selectedStationId === station.id ? 'active' : ''}`} onClick={() => doSetSelected(station)}>
                                <span className="me-auto demi-weight font-black font-small">{type === 'endurvinnslustod' ? station.address : station.title}, <span className="font-black font-small normal-weight text-nowrap">{station.postal_code} {station.area}</span></span>
                                <OpeningHoursSingle station={station} classNames="text-nowrap font-black font-smaller d-flex align-items-center normal-weight opening-hrs-span" />
                                {selectedStationId !== null ?
                                    <a className="d-none d-lg-block ms-2 me-4 me-2 font-smaller text-nowrap demi-weight scale-fix" href={`${parentUrl}?selectedStation=${station.id}`}>Sýna á korti</a>
                                :
                                    <span className="d-none d-lg-block ms-5 me-4 me-2 font-smaller text-nowrap demi-weight scale-fix">Sýna á korti</span>   
                                }
                                <div className='d-none me-4' style={{width: '16px'}}><LocationIcon /></div>
                            </div>
                            {selectedStationId === station.id ?
                                <a className="d-none d-lg-flex border rounded more ms-auto align-items-center justify-content-center" href={parentUrl}><span className='font-light demi-weight font-smaller'>Loka</span></a>
                                :
                                <a className="d-none d-lg-flex border rounded more ms-auto align-items-center justify-content-center" href={station.meta.html_url}><span className='font-light demi-weight font-smaller'>Nánar <span className='hide-text demi-weight font-smaller'>um stöðina</span></span></a>
                            }
                        </li>
                    )}
                </ul>
                { alternatePageLink !== '' &&
                    <>
                    <div className='border-blue rounded-top rounded-bottom mt-5 mb-4 py-4 px-20'>
                        <div className="font-medium demi-weight">Endurvinnslustöðvar</div>
                        <p className="font-medium font-lighter">{alternatePageText}</p> 
                    </div>
                    <div className='border-blue rounded-top rounded-bottom py-3 text-center mb-5'>
                        <a href={alternatePageLink} className="font-smaller demi-weight font-light">Sjá allar endurvinnslustöðvar</a>
                    </div>
                    </>         
                }
            </div>
        )
    }


    if (props.type === 'grenndarstod') {
        return ( <RenderGrenndarStodvar {...props} />)
    } else if (props.type === 'endurvinnslustod'){
        return ( <RenderEndurvinnsluStodvar {...props} />)
    } else {
        return (<RenderMottokustodvarFyrirtaekja {...props}/>)
    }


}

StationIndex.defaultProps = {
    type: 'grenndarstod',
    selectedStationId: null,
    parentUrl: null,
    alternatePageLink: '',
    alternatePageText: '',
}
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getStations, getClosestStations } from '../../slices/stations';
import { setSelected, clearSelected } from '../../slices/selected-station';
import moment from 'moment';
import OpeningHoursSingle from '../opening-hours-single';
import ClosestStationHomePage from './homepage';
import ClosestStationSubcategory from './subcategory';

export default function ClosestStation(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function onSuccess(pos) {
                let args = {
                    position: pos,
                    type: props.type
                }
                if (props.subcategoryId) {
                    args.subcategory = props.subcategoryId
                }
                dispatch(getClosestStations(args))
            },
            function onError(error) {
                console.log("Geolocation error", error)
                let args = {
                    type: props.type
                }
                if (props.subcategoryId) {
                    args.subcategory = props.subcategoryId
                }
                dispatch(getClosestStations(args))
            }
        )
    }, [])
    

    return (
        <>
            {props.callerType === 'subcategory' ?
                <ClosestStationSubcategory {...props}></ClosestStationSubcategory>
                :
                <ClosestStationHomePage {...props}></ClosestStationHomePage>
            }

        </>
    )

}

ClosestStation.defaultProps = {}
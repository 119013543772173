import axios from "axios";
import { getCookie } from "../../utils";

// const API_URL = "/api/v2/pages/";
const API_URL = "/api/get_documents/";

// http://localhost:8000/api/v2/pages/?type=sorpa_cms.PublishedContentPage&fields=published_documents

const getPublishedContent = (args) => {
    let params = '?'

    if(args && args['year']){
        params += `&year=${args.year}`
    }
    if(args && args['type']){
        params += `&type=${args.type}`
    }
    if(args && args['substr']){
        params += `&substr=${args.substr}`
    }
    if(args && args['page']){
        params += `&page=${args.page}`
    }

    return axios
        .get(`${API_URL}${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });

};


const ContentService = {
    getPublishedContent
};

export default ContentService;
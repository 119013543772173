import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getStations } from '../../slices/stations';

import moment from 'moment';

export default function OpeningHoursSingle(props) {

    let {
        station
    } = props

    if (!station.opening_hours_today) {
        return (<></>)
    }


    let now = moment() 

    let openingHourText = ''
    let opens = ""
    let closes = ""
    let stationClosed = false

    if (station && station.opening_hours_today && station.opening_hours_tomorrow) {
        opens = moment(station.opening_hours_today.from, "HH:mm:ss")
        closes = moment(station.opening_hours_today.to, "HH:mm:ss")
        stationClosed = station.opening_hours_today.closed

        let opensTomorrow = moment(station.opening_hours_tomorrow.from, "HH:mm:ss")
        // let closesTomorrow = moment(station.opening_hours_tomorrow.to, "HH:mm:ss")
        let closedTomorrow = station.opening_hours_tomorrow.closed

        if (stationClosed) {
            openingHourText = 'Lokað í dag'
        } else {
            if (now.isBefore(opens)) {
                openingHourText = `Opnar kl. ${opens.format('HH:mm')}`
            } else if (now.isAfter(opens) && now.isBefore(closes)) {
                openingHourText = `Opið til ${closes.format('HH:mm')}`
            } else if (now.isAfter(closes)) {
                if (closedTomorrow) {
                    openingHourText = 'Lokað á morgun'
                } else {
                    // openingHourText = `Opið ${opensTomorrow.format('HH:mm')}-${closesTomorrow.format('HH:mm')} á morgun`
                    openingHourText = `Opnar kl. ${opensTomorrow.format('HH:mm')}`
                }
            }
        }
    }
    let notOpen = stationClosed || now.isBefore(opens) || now.isAfter(closes)
    return (
        <div className={`${props.classNames} opening-hrs position-relative font-medium`}>
            <span className={`dot d-block rounded-circle me-2 ${notOpen ? 'closed' : 'open'}`}></span>
            {openingHourText}
        </div>
    )

}

OpeningHoursSingle.defaultProps = {
    station: {},
    classNames: ''
}
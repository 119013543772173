import axios from "axios";
import { getCookie } from "../../utils";
import moment from 'moment';


const API_URL = "/api/get_pricelist/";


const getPricelist = (args) => {
    let params = '?'
    if (args && args['subcategory']) {
        params = `${params}&subcategory=${args.subcategory}`
    }
    if (args && args['stype']) {
        params = `${params}&stype=${args.stype}`
    }
    
    return axios
        .get(API_URL + `${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};




const pricelistService = {
    getPricelist,
};

export default pricelistService;

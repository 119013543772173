import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/v2/pages/";

// http://localhost:8000/api/v2/pages/?type=sorpa_cms.NewsPage&fields=header_image,excerpt,body
// http://localhost:8000/api/v2/pages/?type=sorpa_cms.NewsPage&order=-first_published_at&fields=header_image,excerpt,body&limit=3

const getNews = (args) => {
    let base = "?type=sorpa_cms.NewsPage"
    let params = "&order=-first_published_at&fields=header_image_thumb,excerpt,body"
    if(args && args['limit']){
        params += `&limit=${args.limit}`
    }
    if(args && args['offset']){
        params += `&offset=${args.offset}`
    }

    return axios
        .get(API_URL + `${base}` + `${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });

};


const NewsService = {
    getNews
};

export default NewsService;
import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'


import HomePageContainer from './homepage-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <HomePageContainer {...props}/>
        </Provider>
    )
  }
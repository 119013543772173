import React, { useEffect} from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { store } from '../../store'

import OpeningHoursDetail from '../../components/opening-hours-detail'

export default function OpeningHoursDetailContainer(props) {

	const dispatch = useDispatch()
    const stations = useSelector ((state) => state.stations.stationList.items)

    let station = stations.filter(s => s.id === props.stationId)
    if (station.length > 0) {
        station = station[0]
    } else {
        station = {}
    }

    return (
        <Provider store={store}>
            <OpeningHoursDetail station={station}/>
        </Provider>
    )
  }
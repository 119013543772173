export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic29ycGFuIiwiYSI6ImNsOTJ1MzY1NzAxN3Izdm9jdWc1emNjbzIifQ.2KcXbe2R8Xz6x4alFOdZ7w';

export const STATION_TYPES = {
    endurvinnslustod: {
        verboseName: "Endurvinnslustöð",
        verboseNamePlural: "Endurvinnslustöðvar"
    },
    grenndarstod: {
        verboseName: "Grenndarstöð",
        verboseNamePlural: "Grenndarstöðvar"
    },
    mottokustod: {
        verboseName: "Móttökustöð",
        verboseNamePlural: "Móttökustöðvar"
    },
    starfsstod: {
        verboseName: "Starfsstöð",
        verboseNamePlural: "Starfsstöðvar"
    },
    "mottokustod,mottokustod_fyrirtaekja": {
        verboseName: "Móttökustaður",
        verboseNamePlural: "Móttökustaðir"
    },
}

export const CONTACT_FORM_LABELS = {
    name: "Nafn",
    phone: "Símanúmer",
    email: "Netfang",
    message: "Skilaboð",
    category: "Flokkur",
}
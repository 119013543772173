import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { submitForm } from '../../slices/contact-us';
import { CONTACT_FORM_ERRORS, CONTACT_FORM_LABELS } from '../../constants';


const ContactUsForm = () => {
    const dispatch = useDispatch()
    const [attachment, setAttachment] = useState(null)
    const [showTunnurError, setShowTunnurError] = useState(false)
    const [showThankYou, setShowThankYou] = useState(false)
    const [showRecaptchaError, setShowRecaptchaError] = useState(false)

    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            submit: 'Submit',
            retUrl: 'https://www.sorpa.is/um-sorpu/abendingar',
        }
    });

    const watchReason = watch("reason")

    useEffect(() => {
        if (watchReason == 'Tunnur') {
            setShowTunnurError(true)
        } else {
            setShowTunnurError(false)
        }
    }, [watchReason])

    // const getInputErrorMsg = (key) => { return CONTACT_FORM_ERRORS[key] }
    const getFormLabel = (key) => { return CONTACT_FORM_LABELS[key] }

    const getInputField = (name, required, pattern = {}) => {
        return (<>
            <label htmlFor={name} className="form-label">{getFormLabel(name)}</label>
            <input id={name} className="form-control" {...register(name, { required: required, pattern: pattern, })} />
            {errors[name] ? <div className="error-msg text-left">{errors[name].message}</div> : null}
        </>)
    }

    const getRadioButton = (name) => {
        return (<>
            <label className="position-relative cursor-pointer">
                <input {...register("reason")} type="radio" name="reason" value={name} />
                {name}
            </label>
        </>
        )
    }

    const onSubmit = (data) => {
        // Nota recaptcha?
        //var response = grecaptcha.getResponse();
        // if (response.length == 0) {
        //     setShowRecaptchaError(true)
        // } else {
        dispatch(submitForm(data)).then((response) => {
            setShowThankYou(true)
        })
        //}
    }


    return (
        // Your JSX code goes here
        <div className="form-page mt-5">
            <div className="alert alert-info p-5 text-center d-none" id="abendingar-success">
                Þakka þér fyrir að senda ábendingu.
                <div className="text-center mt-4">
                    <button type="button" className="btn btn-primary-outline" id="abendingar-another">Senda aðra ábendingu</button>
                </div>
            </div>
            <>
                {showThankYou ?
                    <div className="alert alert-info p-5 text-center" id="abendingar-success">
                        Takk fyrir að hafa samband. <br /> Við reynum að svara fyrirspurn þinni eins fljótt og auðið er.
                    </div>
                    :
                    <form id="abendingar" onSubmit={handleSubmit(onSubmit)}>
                        {showTunnurError &&
                            <div className="alert alert-info mb-5" id="tunnur-error">
                                SORPA sér ekki um sorphirðu eða tunnuleigu. Slíkar fyrirspurnir skal senda á viðeigandi sveitarfélag. <br />
                                SORPA er eingöngu móttökuaðili fyrir úrgang.
                            </div>
                        }
                        <div className="mb-4 position-relative" id="Flokkur">
                            <div className="contact-type d-flex border-blue flex-wrap">
                                <div className="radio-btn cursor-pointer me-5 d-flex">
                                    {getRadioButton("Fyrirspurn")}
                                </div>
                                <div className="radio-btn cursor-pointer me-5 d-flex">
                                    {getRadioButton("Hrós")}
                                </div>
                                <div className="radio-btn cursor-pointer me-5 d-flex">
                                    {getRadioButton("Athugasemd")}
                                </div>
                                <div className="radio-btn cursor-pointer me-5 d-flex">
                                    {getRadioButton("Tunnur")}
                                </div>
                                <div className="radio-btn cursor-pointer me-5 d-flex">
                                    {getRadioButton("Annað")}
                                </div>
                            </div>
                            {errors["reason"] ? <div className="error-msg text-left">Vinsamlegast veldu ástæðu fyrirspurnar</div> : null}
                        </div>
                        <div className="mb-4 position-relative">
                            {getInputField('name', "Vinsamlegast fylltu út nafn")}
                        </div>
                        <div className="mb-4 position-relative">
                            {getInputField('phone', "Vinsamlegast settu inn símanúmer", {
                                value: /^[0-9]{7}$/i,
                                message: 'Símanúmer er ekki með réttu sniði',
                            })}
                        </div>
                        <div className="mb-4 position-relative">
                            {getInputField('email', "Vinsamlegast settu inn netfang", {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Netfangið er ekki með réttu sniði',
                            })}
                        </div>
                        <div className="mb-4 position-relative">
                            <label htmlFor="description" className="form-label">
                                Erindi <span>*</span>
                            </label>
                            <textarea {...register('description', { required: true })} rows="4" cols="50" className="form-control" id="description" ></textarea>
                        </div>
                        {/* <div className="position-relative file-upload-container">
                    <span className="btn">Velja viðhengi... <input type="file" onChange={(ev) => setAttachment(ev.target.files[0])} className="file pt-4" id="Attachments" name="Attachments[]" multiple="" placeholder="" /></span>
                    <span id="file-info" className="font-small"></span>
                </div> */}
                        {/* <div className="my-4 position-relative">
                            <div className="g-recaptcha" data-sitekey="6LenTHgpAAAAABeYtPrJ51NYCFGMhtgLZR9Vp65i" data-callback="recaptchaReturnCallback"></div>
                            <input type="text" id="captchaToken" style={{ display: 'none' }}></input>
                        </div>
                        {showRecaptchaError &&
                            <error>Vinsamlegast staðfestu að þú sért ekki vélmenni</error>
                        } */}
                        <div className="my-5 position-relative">
                            <button type="submit" id="submitButton" className="button" name="submitButton" placeholder="" disabled={showTunnurError}>Senda</button>
                        </div>
                    </form>
                }
            </>
        </div>
    );
};

export default ContactUsForm;


import React from 'react';
import ReactDOM from 'react-dom';
import Wrapper from './wrapper'

$.fn.NewsIndexPage = function () {
  return this.each(function () {
    var options = $(this).data();
    ReactDOM.render(React.createElement(Wrapper, options), this);
  });
};

$(document).ready(function () {
  $('[data-news-index-page-container]').NewsIndexPage();
});


import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/v2/snippets/";

const getCategories = (args) => {
    let params = ""

    return axios
        .get(API_URL + `${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });

};

const setMissedSearch = (args) => {
    let url = "/api/register_search_miss/"

    return axios
        .post(url, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            },
            body: {
                'search_string': args
            }
        })
        .then((response) => {
            return response.data;
        });

};


const SearchService = {
    getCategories,
    setMissedSearch
};

export default SearchService;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PricelistService from "../../api/pricelist";
import { createStore, combineReducers } from 'redux'
//import { setMessage } from "./message";


export const getPricelist = createAsyncThunk(
  "pricelist/get",
  async (args, thunkAPI) => {
    try {
      const response = await PricelistService.getPricelist(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {pricelist: {}};

const pricelistSlice = createSlice({
  name: "pricelist",
  initialState,
  extraReducers: {
    [getPricelist.fulfilled]: (state, action) => {
      state.pricelist[action.meta.arg.stype] = action.payload;
    },
    [getPricelist.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja verðskrá";
    },
  },
});

const { reducer } = pricelistSlice;
export default reducer;
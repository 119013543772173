import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ContactUsService from "../../api/contact-us";

export const submitForm = createAsyncThunk(
    'contact-us/submit',
    async (args, thunkAPI) => {
        try {
            const response = await ContactUsService.submitForm(args);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = { data: [] };

const contactUsSlice = createSlice({
    name: 'contact-us',
    initialState,
    extraReducers: {
        [submitForm.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [submitForm.rejected]: (state, action) => {
            state.error = "Ekki tókst að senda fyrirspurn";
        },
    },
});

const { reducer } = contactUsSlice;
export default reducer;
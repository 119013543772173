import React, { useEffect} from 'react'
import { Provider, useDispatch } from 'react-redux'

import SorpaMap from '../../components/sorpa-map'
import ClosestStation from '../../components/closest_station'
import CategorySearch from '../../components/category_search'
import NextEmptying from '../../components/next_emptying'

import { store } from '../../store'
import { getClosestStations, getStations } from '../../slices/stations'
import { getCategories } from '../../slices/search';

export default function HomePageContainer(props) {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getStations())
        dispatch(getCategories())
    }, [])

    return (
        <Provider store={store}>
            <div className="col-lg-6 col-14 offset-1 d-flex flex-column justify-content-center">
                <div className="header-message" dangerouslySetInnerHTML={{__html: props.header}}></div>
                <CategorySearch showSuggestions={true}></CategorySearch>
                {/* <NextEmptying></NextEmptying> */}
                <div className="d-block d-sm-flex d-lg-block justify-content-sm-between justify-content-lg-start">
                    <ClosestStation type="grenndarstod" link={props.glink}/>
                    <ClosestStation type="endurvinnslustod" link={props.elink}/>
                </div>
            </div>
            <div className="col-lg-7 col-14 offset-1 p-0">
                <SorpaMap/>
            </div>
        </Provider>
    )
  }
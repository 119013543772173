import React, { useEffect} from 'react'
import { Provider, useDispatch } from 'react-redux'

import CategorySearch from '../../components/category_search'

import { store } from '../../store'
import { getCategories } from '../../slices/search';

export default function CategorysearchContainer(props) {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCategories())
    }, [])

    return (
        <CategorySearch showSuggestions={true}></CategorySearch>
    )
  }
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import SearchService from "../../api/search";
import NewsService from "../../api/news";

export const getNews = createAsyncThunk(
    "news/get",
    async (args, thunkAPI) => {
      try {
        const response = await NewsService.getNews(args);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue();
      }
    }
);

const initialState = { data: [] };

const newsSlice = createSlice({
    name: "news",
    initialState,
    extraReducers: {
      [getNews.fulfilled]: (state, action) => {
        state.data = state.data.concat(action.payload.items);
      },
      [getNews.rejected]: (state, action) => {
        state.error = "Ekki tókst að sækja fréttir";
      },
    },
  });


const { reducer } = newsSlice;
export default reducer;
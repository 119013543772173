import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'


import OpeningHoursDetail from './openinghours-detail-container';

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <OpeningHoursDetail {...props}/>
        </Provider>
    )
  }
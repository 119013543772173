import React, { useEffect} from 'react'

import { useDispatch } from 'react-redux'
import { getNews } from '../../slices/news';
import NewsIndexPage from '../../components/news_index';

export default function NewsIndexPageContainer(props) {
    const LIMIT = 12
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNews({limit: LIMIT}))
    }, [])
    
    return (
        <>
        <div class="row justify-content-center">
            <div class="col-lg-12 news-headline-row mt-32 mb-32">
                <h1 class="demi-weight border-bottom">{ props.title }</h1>
            </div>
        </div>
        <div className="row">
            <NewsIndexPage limit={LIMIT}></NewsIndexPage>
        </div>
        </>
    )
  }
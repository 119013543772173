import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');


import "../../sass/main.scss"

// views
require('./views/homepage-container/');
require('./views/station-index-page-container');
require('./views/station-index-standalone-container');
require('./views/news-container/');
require('./views/published-content-page/');
require('./views/subcategory-page-container/');
require('./views/pricelist-container/');
require('./views/openinghours-detail-container/');
require('./views/search-container/');
require('./views/categorysearch-container/');
require('./views/contact-us-container/');
require('./components/top-search');


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StationService from "../../api/stations";
import { createStore, combineReducers } from 'redux'
//import { setMessage } from "./message";


export const getStations = createAsyncThunk(
  "stations/get",
  async (args, thunkAPI) => {
    try {
      const response = await StationService.getStations(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getClosestStations = createAsyncThunk(
  "closestStations/get",
  async (args, thunkAPI) => {
    try {
      const response = await StationService.getClosestStations(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {stationList: {items: []}, closestStations: {geo: false, touched: false}};

const stationsSlice = createSlice({
  name: "stations",
  initialState,
  extraReducers: {
    [getStations.fulfilled]: (state, action) => {
      state.stationList = action.payload;
    },
    [getStations.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja móttökustöðvar";
    },
    [getClosestStations.fulfilled]: (state, action) => {
      let type = action.payload.type
      let data = action.payload.data
      state.closestStations[type] = data
      state.closestStations.geo = action.payload.geo
      state.closestStations.touched = true
    },
    [getClosestStations.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja næstu stöðvar";  
    },
  },
});

const { reducer } = stationsSlice;
export default reducer;
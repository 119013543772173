import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getStations, getClosestStations } from '../../slices/stations';
import { setSelected, clearSelected } from '../../slices/selected-station';
import moment from 'moment';
import OpeningHoursSingle from '../opening-hours-single';
import { STATION_TYPES } from '../../constants';

export default function ClosestStationHomePage(props) {
    // const stations = useSelector((state) => state.stations.stationList)
    const dispatch = useDispatch()
    const closestStations = useSelector((state) => state.stations.closestStations)
    const stations = useSelector((state) => state.stations.stationList)
    const selectedStation = useSelector((state) => state.selectedStation.station)
    const geoEnabled = useSelector((state) => state.stations.closestStations.geo)

    const setSelectedStation = (station) => {
        dispatch(setSelected(station))
        $('.mapboxgl-map')[0].scrollIntoView()
    }

    const type = STATION_TYPES[props.type]

    return (
        <div className={`d-flex closest-station flex-wrap ${props.type == 'grenndarstod' ? 'blue-bg' : 'green-bg'}`}>
            <div className="col-lg-6 col-sm-16 col inner-col">
                {geoEnabled && <span className="text-uppercase d-block label">Næstu</span>}
                <span className="demi-weight font-medium d-block text-capitalize text-break type">{type.verboseNamePlural.toLowerCase()}</span>
            </div>
            <div className="col-lg-10 col-sm-16 col pe-lg-4 inner-col">
                {(closestStations && closestStations[props.type]) ?
                    <>
                        {closestStations[props.type].map((station, i) => {
                            if (props.type == 'grenndarstod') {
                                return <div className="font-medium demi-weight cursor-pointer type" key={i} onClick={() => setSelectedStation(station)}>
                                    {station.address},
                                    <span className="normal-weight font-medium">{station.postal_code} {station.area}</span>
                                </div>
                            } else {
                                return <div className="font-medium demi-weight opening-hrs d-flex align-items-center justify-content-between cursor-pointer" key={i} onClick={() => setSelectedStation(station)}>
                                    {station.address}
                                    {/* <span className="font-black font-medium medium-weight d-flex align-items-center normal-weight">
                                    <span className="dot d-block rounded-circle ms-5 me-2"></span>
                                    Opið til {moment(station.closes, 'hh:mm:ss').format('HH:mm')}
                                </span> */}
                                    <OpeningHoursSingle station={station} classNames="font-black font-medium d-flex align-items-center normal-weight" />
                                </div>
                            }
                        })}
                    </>
                    :
                    <>Sæki upplýsingar um staðsetningu...</>
                }
            </div>
            <div className="see-all col-xl-10 offset-lg-6 text-lg-start text-center col-16 cursor-pointer mt-3">
                <a className="font-medium medium-weight" href={props.link}>Sjá allar {props.type == 'grenndarstod' ? 'grenndarstöðvar' : 'endurvinnslustöðvar'}</a>
            </div>
        </div>

    )


}

ClosestStationHomePage.defaultProps = {}
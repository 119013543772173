import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getStations, getClosestStations } from '../../slices/stations';
import { setSelected, clearSelected } from '../../slices/selected-station';
import moment from 'moment';
import OpeningHoursSingle from '../opening-hours-single';
import { STATION_TYPES } from '../../constants';
import { renderSubtext } from '../../utils';

export default function ClosestStationSubcategory(props) {
    // const stations = useSelector((state) => state.stations.stationList)
    const dispatch = useDispatch()
    const closestStations = useSelector((state) => state.stations.closestStations)
    const geoEnabled = useSelector((state) => state.stations.closestStations.geo)


    const setSelectedStation = (station) => {
        window.location.href = station.meta.html_url
    }

    function grenndarstodClick(station) {
        window.location.href = '/mottokustadir/grenndarstodvar/?selectedStation=' + station.id 
    }

    const type = STATION_TYPES[props.type]

    return (
        <>
            {closestStations.touched && closestStations[props.type] && closestStations[props.type].length > 0 &&

                <div className="closest-station mb-70">
                    <div>
                        <div className="d-flex mb-4">
                            <span className="demi-weight text-capitalize heading">{type.verboseNamePlural}</span>
                            {props.link && <a className="font-medium ms-auto medium-weight blue primary-btn border-blue d-none d-lg-block" href={props.link}>Sjá allar {type.verboseNamePlural.toLowerCase()}</a>}
                        </div>
                        <div>{geoEnabled}</div>
                        {geoEnabled ? 
                            <span className="font-small mb-1">Stöðvar nálægt mér:</span>
                            :
                            <>
                                <span className="font-small mb-1">Staðsetning er ekki leyfð í vafra hjá þér, vinsamlegast leyfðu staðsetningu til að sýna stöðvar nálægt þér.</span>
                                <br />
                                <span className="font-small mb-1">Dæmi um stöðvar:</span>
                            </>
                        }
                        {(closestStations && closestStations[props.type]) ?
                            <>
                                {closestStations[props.type].map((station, i) => {
                                    if (props.type == 'grenndarstod') {
                                        return (
                                            <div className="grenndarstod opening-hrs-container font-medium demi-weight cursor-pointer border station" onClick={() => grenndarstodClick(station)} key={i} style={{ cursor: "text" }}>
                                                <span className="normal-weight font-small">{station.address}, {station.postal_code} {station.area}
                                                </span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="endurvinnslustod font-small demi-weight station opening-hrs-container d-flex align-items-center justify-content-between cursor-pointer" key={i} onClick={() => setSelectedStation(station)}>
                                                {props.showTitle &&
                                                    <>
                                                        {station.title}<br />
                                                    </>
                                                }
                                                {station.address}, {station.postal_code} {station.area}
                                                {station.opening_hours_today.starred &&
                                                    <div class="normal-weight font-small">
                                                        {renderSubtext(station.opening_hours_today.subtext)}
                                                    </div>
                                                }
                                                <OpeningHoursSingle station={station} classNames="font-black font-smaller d-flex align-items-center normal-weight text-nowrap ms-1" />
                                            </div>
                                        )
                                    }
                                })}
                            </>
                            :
                            <>Sæki upplýsingar um staðsetningu...</>
                        }
                        {props.link && <a className="font-medium medium-weight blue primary-btn border-blue d-block d-lg-none " href={props.link}>Sjá allar {type.verboseNamePlural.toLowerCase()}</a>}
                    </div>
                </div>
            }
            {!closestStations.touched && props.showFetchInfo &&
                <div className="closest-station mb-70">
                    <div>
                        <div className="d-flex mb-4">
                            Sæki upplýsingar um staðsetningu...
                        </div>
                    </div>
                </div>
            }
        </>

    )


}

ClosestStationSubcategory.defaultProps = {
    type: "grenndarstod",
    showFetchInfo: false,
    showTitle: false,
}

import React from 'react';
import ReactDOM from 'react-dom';
// import MapContainer from './map-container';
import Wrapper from './wrapper'

$.fn.pricelistContainer = function () {
  return this.each(function () {
    var options = $(this).data();
    ReactDOM.render(React.createElement(Wrapper, options), this);
  });
};

$(document).ready(function () {
  $('[data-pricelist-container]').pricelistContainer();
});


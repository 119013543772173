import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { store } from '../../store'
import Pricelist from '../../components/pricelist'
const bootstrap = require('bootstrap');


export default function PricelistContainer(props) {

    const dispatch = useDispatch()
    const pricelist = useSelector((state) => state.pricelist.pricelist)

    useEffect(() => {
        let hash = window.location.hash.replace("#", "")
        if (hash !== "") {
            if (hash.split("-").length == 2) {
                try {
                    bootstrap.Tab.getOrCreateInstance(document.getElementById(hash.split("-")[0]+'-tab')).show()
                    $('#'+hash.split("-")[0]+'-'+hash.split("-")[1])[0].scrollIntoView()
                } catch(e){console.log(e)}
            }
        }
    }, [pricelist])

    function clickableAnchors(){
        return (
            <div className='mb-5 d-flex justify-content-md-end flex-wrap'> 
                <a href="#fyrirtaeki-endurvinnslustodvar" className="me-3 font-small mt-2 mt-md-0">Endurvinnslustöðvar</a>
                <a href="#fyrirtaeki-mottokustod" className="me-3 font-small mt-2 mt-md-0">Móttöku- og flokkunarstöð</a>
                <a href="#fyrirtaeki-gaja" className="me-3 font-small mt-2 mt-md-0">GAJA</a>
                <a href="#fyrirtaeki-urdunarstadur" className="me-3 font-small mt-2 mt-md-0">Urðunarstaður</a>
            </div>
        )
    }


    return (
        <Provider store={store}>
            <ul className="nav nav-tabs mb-5 flex-nowrap" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="einstaklingar-tab" data-bs-toggle="tab" data-bs-target="#einstaklingar" type="button" role="tab" aria-controls="einstaklingar" aria-selected="true">Einstaklingar</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="fyrirtaeki-tab" data-bs-toggle="tab" data-bs-target="#fyrirtaeki" type="button" role="tab" aria-controls="fyrirtaeki" aria-selected="false">Fyrirtæki</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="einstaklingar" role="tabpanel" aria-labelledby="einstaklingar-tab">
                    <h2 id="einstaklingar-endurvinnslustodvar">Endurvinnslustöðvar <a className="headerlink" href="#einstaklingar-endurvinnslustodvar">¶</a></h2>
                    <Pricelist displayType="grouped" stationType={"50"} displayGroups={['Einstaklingar']} />
                </div>
                <div className="tab-pane fade" id="fyrirtaeki" role="tabpanel" aria-labelledby="fyrirtaeki-tab">
                    {clickableAnchors()}
                    <h2 id="fyrirtaeki-endurvinnslustodvar">Endurvinnslustöðvar <a className="headerlink" href="#fyrirtaeki-endurvinnslustodvar">¶</a></h2>
                    <Pricelist displayType="grouped" stationType={"50"} displayGroups={['Fyrirtæki']} />
                    <h2 id="fyrirtaeki-mottokustod">Móttöku- og flokkunarstöð í Gufunesi <a className="headerlink" href="#fyrirtaeki-mottokustod">¶</a></h2>
                    <Pricelist displayType="grouped" stationType={"40"} />
                    <h2 id="fyrirtaeki-gaja">GAJA gas- og jarðgerðarstöð <a className="headerlink" href="#fyrirtaeki-gaja">¶</a></h2>
                    <Pricelist displayType="grouped" stationType={"23"} />
                    <h2 id="fyrirtaeki-urdunarstadur">Urðunarstaður í Álfsnesi <a className="headerlink" href="#fyrirtaeki-urdunarstadur">¶</a></h2>
                    <Pricelist displayType="grouped" stationType={"21"} />
                </div>
            </div>
        </Provider>
    )
}
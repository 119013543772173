import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux'
import { store } from '../../store'
import { SvgSearchIcon, CloseIcon } from '../svg'

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
}

function TopSearch(props) {
	const appId = window.algolia.appId
	const ix = window.algolia.index
	const frontendKey = window.algolia.frontendKey
    const algoliasearch = require("algoliasearch");
	const client = algoliasearch(appId, frontendKey);
	const index = client.initIndex(ix);

    const searchSettings = { 
		'facetFilters': [
			[
			 "model:sorpa_cms.NewsPage",
			 "model:sorpa_cms.StandardPage",
             "model:sorpa_cms.StationPage",
			],
            "wagtailcore__Page.live:true"],
		'distinct': 0,
        "hitsPerPage": 20,
	}

    const dispatch = useDispatch();
    const [searching, setSearching] = useState(false);
    const [inputRef, setInputFocus] = useFocus();
    const [searchInput, setSearchInput] = useState('');
    const [searchDisplay, setSearchDisplay] = useState("");
    const containerRef = useRef();
	useOnClickOutside(containerRef, () => {clearSearch();});

    	// Hook
	function useOnClickOutside(ref, handler) {
		useEffect(() => {
			const listener = (event) => {
			// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target) || window.outerWidth < 992 ) {
					return;
				}
				handler(event);
			};
			document.addEventListener("mousedown", listener);
			document.addEventListener("touchstart", listener);
			return () => {
				document.removeEventListener("mousedown", listener);
				document.removeEventListener("touchstart", listener);
			};
		},
		// Add ref and handler to effect dependencies
		[ref, handler]
		);
	}


    useEffect(() => {
		if(searchInput.length >= 2){
			index.search(searchInput, searchSettings)
				.then(({ hits }) => {
					console.log("Hits: ", hits)
					setSearchDisplay(hits)
				})
		} else {
			setSearchDisplay("")
		}
	}, [searchInput])
    
    useEffect(() => {
        if(searching){
            $('body').addClass('search-open')
        } else {
            $('body').removeClass('search-open')
        }
    }, [searching])

    function clearSearch() {
		setSearchInput("")
		setSearchDisplay("")
        setSearching(false)
	}


    const RenderSearch = () => {
		return (<ul className={`categories position-absolute w-100 list-group-flush rounded-bottom ${searchDisplay ? 'list-open' : ''}`}>
			{ searchDisplay && searchDisplay.map((item, i) => (
                item.wagtailcore__Page && !(item.model === "sorpa_cms.StationPage" && item.wagtailcore__Page.get_full_url.includes('mottokustadir')) &&
                    <li className="list-group-item border-0 mb-40">
                        <a href={item.wagtailcore__Page.get_full_url}>
                        <span className="breadcrumb mb-0">{ item.sorpa_cms__BaseStandardPage && item.sorpa_cms__BaseStandardPage.breadcrumb }</span>
                        <h3 className="demi-weight d-inline">{ item.wagtailcore__Page.title }</h3>
                        <span className="font-medium d-block excerpt font-lighter">{ item[item.model.replace(".", "__")] && item[item.model.replace(".", "__")].search_excerpt ? item[item.model.replace(".", "__")].search_excerpt : ''}</span>
                        <span className="font-medium medium-weight d-block mt-3">Lesa meira...</span>
                        </a>
                    </li>
                )
			)}  
		</ul>);
	}
    
    return (
        <>
            <div className="outer-search-container" ref={containerRef}>
                <div className={`search-container overflow-hidden cursor-pointer position-absolute top-50 end-0 ${ searching && 'is-focused'}`}>
                    <div className="search" onClick={() => {setSearching(true); setInputFocus()}} >
                        <SvgSearchIcon />
                    </div>
                    <label htmlFor="content-search" className="visually-hidden">Sláðu inn leitarorð</label>
                    <input ref={inputRef} onChange={e => { setSearchInput(e.target.value) }} value={searching ? searchInput : ''} type="text" id="content-search" placeholder="Sláðu inn leitarorð" className={`w-100 search-input  ${ searching ? 'border-blue' : 'border-0'}`}/>
                    <div className="search-results-container">
                        { searchDisplay && 
                            <RenderSearch></RenderSearch>
                        }
                    </div>
                </div>
                <div onClick={() => { clearSearch()}} className={`close-search position-absolute h-100 top-0 cursor-pointer align-items-center justify-content-center  ${ searching ? 'd-flex' : 'd-none'}`}>
                    <CloseIcon />
                </div>
            </div>
        </>
    )
}

function Wrapper(props) {
    return (
        <Provider store={store}>
            <TopSearch {...props} />
        </Provider>
    )
}

$.fn.TopSearchInit = function () {
    return this.each(function () {
      var options = $(this).data();
      ReactDOM.render(React.createElement(Wrapper, options), this);
    });
};
  
$(document).ready(function () {
    $('[data-top-search-container]').TopSearchInit();
});
import React from 'react'
import { Provider, useDispatch } from 'react-redux'
import { store } from '../../store'
import ContactUsContainer from './contact-us-container';


export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <ContactUsContainer {...props}/>
        </Provider>
    )
  }
import React, { useEffect} from 'react'
import { Provider, useDispatch } from 'react-redux'
import { getContent } from '../../slices/published-content'

// import StationIndex from '../../components/station_index';
import Documents from '../../components/published-documents'

export default function PublishedContentPageContainer(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getContent())
    }, [])
    
    return (
        <>
        <div className="container-fluid clamped">
            <div class="title-block row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-14 mb-4 header-container position-relative img-show ps-0">
                    <div class="title-container position-relative show-bg">
                        <h1 class="demi-weight">{ props.title }</h1>
                    </div>
                </div>
            </div>
            <Documents {...props}></Documents>
        </div>
        </>
    )
  }
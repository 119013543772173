import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'

import PublishedContentPageContainer from './published-content-page-container'

export default function Wrapper(props) {

    // useEffect(() => {
    //     dispatch(getContent())
    // }, [])

    return (
        <Provider store={store}>
            <PublishedContentPageContainer {...props} />
        </Provider>
    )
  }
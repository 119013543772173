import { createSlice } from '@reduxjs/toolkit'

const initialState = { station: null }

const selectedStationSlice = createSlice({
  name: 'selectedStation',
  initialState,
  reducers: {
    setSelected(state, station){
        state.station = station.payload
    },
    clearSelected(state){
        state.station = null
    }
  },
})

export const { setSelected, clearSelected } = selectedStationSlice.actions
const { reducer } = selectedStationSlice;
export default reducer;

import React, { useEffect} from 'react'

import { Provider, useDispatch } from 'react-redux'
import { store } from '../../store'
import SorpaMap from '../../components/sorpa-map';
import StationIndex from '../../components/station_index';
import { getStations } from '../../slices/stations';

export default function StationIndexStandaloneContainer(props) {

    const dispatch = useDispatch()

    useEffect(() => {
        let tmpType = props.type
        if (tmpType === 'mottokustod' || tmpType === 'mottokustod_fyrirtaekja') {
            tmpType = 'mottokustod,mottokustod_fyrirtaekja'
        }
        dispatch(getStations({'station_types': tmpType }))
    }, [])
    
    return (
        <div className="col-xl-12 offset-xl-2 col-lg-15 col-14 offset-1 ">
            <StationIndex {...props}></StationIndex>
        </div>
    )
  }
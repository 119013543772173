import React from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store } from '../../store'

import SearchContainer from './search-container';

export default function Wrapper(props) {


    return (
        <Provider store={store}>
            <SearchContainer {...props} />
        </Provider>
    )
  }
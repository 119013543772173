import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { getContent } from '../../slices/published-content'
import { ArrowDown, ArrowHead, FileIcon, CloseIcon, SearchIcon } from '../svg'

// Needs to be outside parent element do to react-rerendering template and losing focus
const SearchWindow = ({ substr, setSubstr, showClearButton, clearAllFilters }) => {
    return (<div key='filter' className="position-relative">
        <label htmlFor="doc-filter" className="visually-hidden">Sláðu inn leitarorð</label>
        <input onChange={e => { setSubstr(e.target.value) }} value={substr} type="text" id="doc-filter" placeholder="Sláðu inn leitarorð" className="border-blue font-black w-100 font-small medium-weight" />
        {showClearButton &&
            <div onClick={() => clearAllFilters()} className="clear-filter position-absolute end-0 cursor-pointer d-flex align-items-center"><span className="font-lighter font-smaller me-2">Hreinsa</span><div className="clear-icon-container d-flex align-items-center justify-content-center overflow-hidden"><CloseIcon /></div></div>
        }
    </div>)
}

const PAGESIZE = 30
const ELLIPSIS = '…'

export default function Documents(props) {
    const dispatch = useDispatch()
    const documents = useSelector((state) => state.documents.data)
    const [year, setYear] = useState("")
    const [type, setType] = useState("")
    const [substr, setSubstr] = useState("")
    const [showClearButton, setShowClearButton] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        let params = {}
        year ? params['year'] = year : ''
        type ? params['type'] = type : ''
        substr ? params['substr'] = substr : ''
        params['page'] = page
        dispatch(getContent(params))
        // If any parameters, show Hreinsa button, else hide it
        setShowClearButton(Object.keys(params).length > 0)
    }, [year, type, substr, page])

    const clearAllFilters = () => {
        setYear("")
        setType("")
        setSubstr("")
    }

    const numPages = Math.ceil(documents.count / PAGESIZE)

    const TypeDropdown = () => {
        return <select className="w-100 font-small medium-weight border-blue cursor-pointer" value={type} onChange={(e) => setType(e.target.value)}>
            <option value={""}>Tegund</option>
            {props.documentTypes.map((item) => {
                return <option value={item}>{item}</option>
            })}
        </select>
    }

    const YearDropdown = () => {
        return <select className="w-100 font-small medium-weight border-blue cursor-pointer" value={year} onChange={(e) => setYear(e.target.value)}>
            <option value={""}>Ártal</option>
            {props.years.map((item) => {
                return <option value={item}>{item}</option>
            })}
        </select>
    }

    const DocumentList = () => {
        return <>
            {documents.results.map((doc) => {
                return (
                    <a href={doc.url}>
                        <div className="document cursor-pointer mb-3 d-flex align-items-center">
                            <div className="me-4">
                                <FileIcon />
                            </div>
                            <div className="font-lighter pt-2 col me-4"><div>{doc.title} <span className="doc-type">{doc.document_type}</span></div></div>
                            <div><span className="font-medium font-smaller me-4 date font-lighter d-block d-md-inline-block">{moment(doc.date).format('DD.MM.YYYY')}</span></div>
                            <div className="ms-auto">
                                <ArrowDown />
                            </div>
                        </div>
                    </a>
                )
            })}
        </>
    }

    const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);

    const getPages = (number = 1, onEachSide = 2, onEnds = 1) => {
        let pages = null

        if (numPages <= (onEachSide, onEnds) * 2) {
            return range(1, numPages)
        }

        if (number > (1 + onEachSide + onEachSide) + 1) {
            pages = range(1, onEnds).concat(ELLIPSIS).concat(range(number - onEachSide, number))
        } else {
            pages = range(1, number)
        }
        if (number < (numPages - onEachSide - onEnds) - 1) {
            pages = pages.concat(range(number + 1, number + onEachSide)).concat(ELLIPSIS).concat(range(numPages - onEnds + 1, numPages))
        } else {
            pages = pages.concat(range(number + 1, numPages))
        }

        return pages
    }

    const DocumentPaginator = () => {

        let pages = getPages(page)

        return (
            <>
                {documents.count > documents.results.length &&
                <>
                    <div className="col-xl-14 mt-5 text-center">
                        <span className="font-gray font-small">Sýni niðurstöður {(page - 1) * PAGESIZE + 1} - {page === numPages ? documents.count : (page) * PAGESIZE} af {documents.count} niðurstöðum.</span>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-15 pagination-wrapper mb-5 mt-4 d-flex justify-content-center">
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className={`page-item d-flex justify-content-center align-items-center ms-0 wider ${documents.previous ? "" : "disabled"}`}>
                                    {documents.previous ?
                                        <a href="javascript:void(0)" onClick={() => setPage(page - 1)} className="page-link text font-small ps-0 text-nowrap">Fyrri síða</a>
                                        :
                                        <span className="page-link font-small ps-0 text-nowrap">Fyrri síða</span>
                                    }
                                </li>
                                {pages.map(p =>
                                    <li className={`page-item d-flex justify-content-center align-items-center ${p === ELLIPSIS ? "disabled" : ""} ${page === p ? "active" : ""}`}>
                                        { p === ELLIPSIS ? 
                                        <a className="page-link font-small" href="javascript:void(0)" >{p}</a>
                                        :    
                                        <a className="page-link font-small" href="javascript:void(0)" onClick={() => setPage(p)}>
                                        {p}
                                        </a>
                                    }
                                    </li>
                                )}
                                <li className={`page-item d-flex justify-content-center align-items-center me-0 wider ${documents.next ? "" : "disabled"}`}>
                                    {documents.next ?
                                        <a href="javascript:void(0)" onClick={() => setPage(page + 1)} className="page-link text font-small pe-0 text-nowrap">Næsta síða</a>
                                        :
                                        <span className="page-link font-small pe-0 text-nowrap">Næsta síða</span>
                                    }
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </>
                }
            </>
        )
    }

    return (
        <>
            {documents &&
                <>
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-14 col-14">
                            <div className="filter-container d-flex justify-content-between row">
                                <div className="col position-relative">
                                    <div className="inner-container position-relative">
                                        <TypeDropdown />
                                        <ArrowHead />
                                    </div>
                                </div>
                                <div className="col position-relative">
                                    <div className="inner-container position-relative">
                                        <YearDropdown />
                                        <ArrowHead />
                                    </div>
                                </div>
                                <div className="col input-col">
                                    <SearchWindow substr={substr} setSubstr={setSubstr} showClearButton={showClearButton} clearAllFilters={clearAllFilters} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 col-md-14 col-16">
                            <DocumentList />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <DocumentPaginator />
                    </div>
                </>
            }
        </>
    )

}

Documents.defaultProps = {}